import React from "react"
import Layout from "../components/Layout"

export default function FourOhFourPage() {
    return (
        <Layout>
          <div className="container">
            <h1>404</h1>
          </div>
        </Layout>
    )
}
